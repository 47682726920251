import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "@shared/components/lib/index";
import "./css/main.css";
import { createTheme } from "@mui/material/styles";

export default function Main({ children }) {
  const queryClient = new QueryClient();

  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            maxWidth: "100%", // Allow dynamic width
            margin: "0 auto",
            paddingLeft: "2vw", // Use viewport-based padding
            paddingRight: "2vw",
            [`@media (min-width: 1920px)`]: {
              maxWidth: "100%", // 85% width on XL screens
            },
            [`@media (min-width: 2560px)`]: {
              maxWidth: "80%", // 80% width on 2K screens
            },
            [`@media (min-width: 3200px)`]: {
              maxWidth: "75%", // 75% width on 4K screens
            },
            [`@media (min-width: 5120px)`]: {
              maxWidth: "70%", // Ultra-wide monitors
            },
            [`@media (min-width: 7680px)`]: {
              maxWidth: "65%", // 8K screens
            },
            [`@media (min-width: 10240px)`]: {
              maxWidth: "60%", // Future ultra-wide screens
            },
          },
        },
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </QueryClientProvider>
  );
}
