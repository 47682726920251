import React, { useState } from "react";
import { getRequestUI } from "common-utils/utils/api";
import { DateTime } from "luxon";
import CustomizedPieChart from "../components/CustomPieChart";
import { useQuery } from "react-query";
import { DateRangePicker } from "react-date-range";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  CardContent,
  Card,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
const formatDate = (date) => DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

const formatDateDisplay = (date) =>
  DateTime.fromJSDate(date).toFormat("dd LLL yyyy");

const RetailerPerformance = () => {
  const today = new Date();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 29,
    ),
    endDate: today,
  });
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [open, setOpen] = useState(false);
  const { auth, userClaims } = useOutletContext() || {};
  const retailerId = userClaims?.retailerId;

  const handleFieldClick = () => setOpen(true);

  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedDateRange({ startDate, endDate });
  };

  const handleDialogClose = () => setOpen(false);

  const handleDialogConfirm = () => {
    setDateRange(selectedDateRange);
    setOpen(false);
  };

  const { data: carrierList, isLoading: isCarriersLoading } = useQuery(
    ["carriers", retailerId],
    async () => {
      if (!retailerId) return [];
      return getRequestUI(`/retailer/carriers/${retailerId}`);
    },
    { enabled: !!retailerId, refetchOnWindowFocus: false },
  );

  const { data: countriesList, isLoading: isCountriesLoading } = useQuery(
    ["countries"],
    () => getRequestUI("/countries"),
    { refetchOnWindowFocus: false },
  );

  const fetchAnalyticsData = async (endpoint) => {
    const filters = {
      startDate: formatDate(dateRange.startDate),
      endDate: formatDate(dateRange.endDate),
      ...(selectedCarrier ? { carrierUUID: selectedCarrier } : {}),
      ...(selectedCountry ? { country: selectedCountry } : {}),
    };
    return getRequestUI(endpoint, filters);
  };

  const queryOptions = {
    enabled: !!dateRange.startDate && !!dateRange.endDate,
    refetchOnWindowFocus: false,
  };

  const { data: vehicleTypeData, isLoading: vehicleTypeDataLoading } = useQuery(
    [
      "vehicle-type",
      dateRange.startDate,
      dateRange.endDate,
      selectedCarrier,
      selectedCountry,
    ],
    () => fetchAnalyticsData("/retailer/analytics/break-down/vehicle-type"),
    queryOptions,
  );

  return (
    <Box sx={{ width: "100%", padding: "0px", margin: "0px" }}>
      <Box
        sx={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {" "}
        <Typography
          variant="body1"
          sx={{ marginRight: "8px", marginTop: "10px" }}
        >
          Showing data for:
        </Typography>
        {/* Country Filter Dropdown */}
        <TextField
          select
          value={selectedCountry || ""}
          onChange={(e) => setSelectedCountry(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
          disabled={isCountriesLoading}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        >
          <option value="">All Countries</option>
          {(countriesList ?? []).map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </TextField>
        <TextField
          select
          value={selectedCarrier || ""}
          onChange={(e) => setSelectedCarrier(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
          disabled={isCarriersLoading}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        >
          <option value="">All Carriers</option>
          {(carrierList ?? []).map((carrier) => (
            <option key={carrier.uuid} value={carrier.uuid}>
              {carrier.exportName || carrier.name}
            </option>
          ))}
        </TextField>
        {/* Date Range Input Field */}
        <TextField
          variant="outlined"
          size="small"
          value={`${formatDateDisplay(dateRange.startDate)} - ${formatDateDisplay(
            dateRange.endDate,
          )}`}
          onClick={handleFieldClick}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0", // Light gray border
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        />
        {/* Date Picker Dialog */}
        <Dialog open={open} onClose={handleDialogClose}>
          <DialogContent>
            <Grid
              container
              direction="column"
              sx={{
                padding: "20px 50px",
              }}
            >
              <Grid item>
                <DateRangePicker
                  ranges={[
                    {
                      startDate: selectedDateRange.startDate,
                      endDate: selectedDateRange.endDate,
                      key: "selection",
                    },
                  ]}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={true}
                />
              </Grid>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button variant="text" onClick={handleDialogClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDialogConfirm}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: 0,
          backgroundColor: "rgb(239, 243, 246)",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        {/* Pie Charts */}
        <Box sx={{ width: "100%" }}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "16px",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{ flex: "1 1 calc(33.333% - 16px)", minWidth: "300px" }}
                >
                  <CustomizedPieChart
                    data={vehicleTypeData?.vehicleTypeData.percents || []}
                    title="Sustanability"
                    subtitle=""
                    loading={vehicleTypeDataLoading}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default RetailerPerformance;
