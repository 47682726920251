/* TODO [mark] move to shared componentes */
import { useEffect, useRef } from "react";
import { debounce } from "lodash";

const KeyStrokeListener = ({ keyboardInput }) => {
  const ALLOWED_INPUT_REGEX = /\w+/i;

  const inputSequence = useRef("");

  const debouncedKeyboardInput =
    keyboardInput &&
    debounce((input) => {
      keyboardInput(input);
      inputSequence.current = "";
    }, 250);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;
      if (
        activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA"
      ) {
        return;
      }

      const { key } = event;
      if (key && key.length === 1 && ALLOWED_INPUT_REGEX.test(key)) {
        debouncedKeyboardInput((inputSequence.current += key));
      } else {
        inputSequence.current = "";
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      debouncedKeyboardInput.cancel();
    };
  }, [keyboardInput]);

  return null; // Invisible component
};

export default KeyStrokeListener;
