import React from "react";
import { useQuery } from "react-query";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { getRequestUI } from "common-utils/utils/api";
import { useOutletContext } from "react-router-dom";
import dompurify from "dompurify";

const RetailerInsights = () => {
  const { userClaims } = useOutletContext() || {};
  const retailerId = userClaims?.retailerId;
  const sanitizeHtml = (html) =>
    dompurify.sanitize(html, { USE_PROFILES: { html: true } });

  const styles = {
    text: {
      fontSize: "14px",
      textIndent: 0,
      fontFamily: "'Nunito', sans-serif",
    },
  };

  const { data, isLoading } = useQuery(
    ["retailer-insights-display", retailerId],
    async () => {
      if (!retailerId) return [];
      return getRequestUI(`/retailer/insights/${retailerId}`);
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "0px" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        spacing={2}
        sx={{
          margin: "0 -16px",
        }}
      >
        {data?.insights.map((item) => (
          <Grid item xs={12} md={4} key={item.id}>
            <Card
              sx={{
                height: "350px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "hidden",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                border: "1px solid #ddd",
                borderRadius: "8px",
                paddingLeft: "0",
                paddingRight: "calc(2 * var(--mui-spacing))",
              }}
            >
              <CardContent
                sx={{
                  padding: "16px",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h7"
                  sx={{
                    mb: 1,
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.title}
                </Typography>
                <Box
                  sx={{
                    flex: 1,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 10,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <Typography sx={styles.text}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(item.text),
                      }}
                      sx={{
                        "& p": {
                          margin: 0,
                          lineHeight: "1.5",
                        },
                      }}
                    />
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RetailerInsights;
