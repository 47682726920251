import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { getRequestUI } from "common-utils/utils/api";
import { DateTime } from "luxon";
import { DateRangePicker } from "react-date-range";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useOutletContext } from "react-router-dom";

const formatDate = (date) => DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
const formatDateDisplay = (date) =>
  DateTime.fromJSDate(date).toFormat("dd LLL yyyy");

const RetailerBreakdown = () => {
  const today = new Date();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 29,
    ),
    endDate: today,
  });
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange);
  const [open, setOpen] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const { auth, userClaims } = useOutletContext() || {};
  const retailerId = userClaims?.retailerId;

  const handleFieldClick = () => setOpen(true);

  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedDateRange({ startDate, endDate });
  };

  const handleDialogClose = () => setOpen(false);

  const handleDialogConfirm = () => {
    setDateRange(selectedDateRange);
    setOpen(false);
  };

  const { data: carrierList, isLoading: isCarriersLoading } = useQuery(
    ["carriers", retailerId],
    async () => {
      if (!retailerId) return [];
      return getRequestUI(`/retailer/carriers/${retailerId}`);
    },
    { enabled: !!retailerId, refetchOnWindowFocus: false },
  );

  const { data: countriesList, isLoading: isCountriesLoading } = useQuery(
    ["countries"],
    () => getRequestUI("/countries"),
    { refetchOnWindowFocus: false },
  );

  const fetchAnalyticsData = async (endpoint) => {
    const filters = {
      startDate: formatDate(dateRange.startDate),
      endDate: formatDate(dateRange.endDate),
      ...(selectedCarrier ? { carrierUUID: selectedCarrier } : {}),
      ...(selectedCountry ? { country: selectedCountry } : {}),
    };
    return getRequestUI(endpoint, filters);
  };

  const queryOptions = {
    enabled: !!dateRange.startDate && !!dateRange.endDate,
    refetchOnWindowFocus: false,
  };

  const { data: totalData, isLoading: totalDataLoading } = useQuery(
    [
      "total",
      dateRange.startDate,
      dateRange.endDate,
      selectedCountry,
      selectedCarrier,
    ],
    () => fetchAnalyticsData("/retailer/analytics/total"),
    queryOptions,
  );

  const {
    data: onTimePerformanceData,
    isLoading: onTimePerformanceDataLoading,
  } = useQuery(
    [
      "on-time-performance",
      dateRange.startDate,
      dateRange.endDate,
      selectedCarrier,
      selectedCountry,
    ],
    () =>
      fetchAnalyticsData("retailer/analytics/break-down/ontime-performance"),
    queryOptions,
  );

  const {
    data: totalFailedDeliveriesData,
    isLoading: totalFailedDeliveriesDataLoading,
  } = useQuery(
    [
      "total-failed-orders",
      dateRange.startDate,
      dateRange.endDate,
      selectedCarrier,
      selectedCountry,
    ],
    () =>
      fetchAnalyticsData("retailer/analytics/break-down/total-failed-orders"),
    queryOptions,
  );
  const { data: hitRateData, isLoading: hitRateDataLoading } = useQuery(
    [
      "first-attempt",
      dateRange.startDate,
      dateRange.endDate,
      selectedCarrier,
      selectedCountry,
    ],
    () => fetchAnalyticsData("/retailer/analytics/break-down/hit-rate"),
    queryOptions,
  );

  const {
    data: lateCulpabilitiesPerformanceData,
    isLoading: lateCulpabilitiesPerformanceDataLoading,
  } = useQuery(
    [
      "late-culpabilities-performance",
      dateRange.startDate,
      dateRange.endDate,
      selectedCarrier,
      selectedCountry,
    ],
    () =>
      fetchAnalyticsData("retailer/analytics/break-down/late-cuplabilities"),
    queryOptions,
  );

  const { data: failedDeliveriesData, isLoading: failedDeliveriesDataLoading } =
    useQuery(
      [
        "failed-deliveries",
        dateRange.startDate,
        dateRange.endDate,
        selectedCarrier,
        selectedCountry,
      ],
      () =>
        fetchAnalyticsData("/retailer/analytics/break-down/failed-deliveries"),
      queryOptions,
    );

  const { data: vehicleTypeData, isLoading: vehicleTypeDataLoading } = useQuery(
    [
      "vehicle-type",
      dateRange.startDate,
      dateRange.endDate,
      selectedCarrier,
      selectedCountry,
    ],
    () => fetchAnalyticsData("/retailer/analytics/break-down/vehicle-type"),
    queryOptions,
  );

  const {
    data: deliveryPerformanceData,
    isLoading: deliveryPerformanceDataLoading,
  } = useQuery(
    [
      "delivery-performance",
      dateRange.startDate,
      dateRange.endDate,
      selectedCarrier,
      selectedCountry,
    ],
    () =>
      fetchAnalyticsData("/retailer/analytics/break-down/delivery-performance"),
    queryOptions,
  );

  const { data: totalDeliveredData, isLoading: totalDeliveredDataLoading } =
    useQuery(
      [
        "total-delivered",
        dateRange.startDate,
        dateRange.endDate,
        selectedCarrier,
        selectedCountry,
      ],
      () => fetchAnalyticsData("retailer/analytics/break-down/total-delivered"),
      queryOptions,
    );

  return (
    <>
      {/* Date Picker Section */}
      <Box
        sx={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ marginRight: "8px", marginTop: "10px" }}
        >
          Showing data for:
        </Typography>
        {/* Country Filter Dropdown */}
        <TextField
          select
          value={selectedCountry || ""}
          onChange={(e) => setSelectedCountry(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
          disabled={isCountriesLoading}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        >
          <option value="">All Countries</option>
          {(countriesList ?? []).map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </TextField>
        <TextField
          select
          value={selectedCarrier || ""}
          onChange={(e) => setSelectedCarrier(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
          disabled={isCarriersLoading}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        >
          <option value="">All Carriers</option>
          {(carrierList ?? []).map((carrier) => (
            <option key={carrier.uuid} value={carrier.uuid}>
              {carrier.exportName || carrier.name}
            </option>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          size="small"
          value={`${formatDateDisplay(dateRange.startDate)} - ${formatDateDisplay(
            dateRange.endDate,
          )}`}
          onClick={handleFieldClick}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        />

        <Dialog open={open} onClose={handleDialogClose}>
          <DialogContent>
            <Grid
              container
              direction="column"
              sx={{
                overflow: "hidden",
                padding: "20px 50px",
              }}
            >
              <Grid item>
                <DateRangePicker
                  ranges={[
                    {
                      startDate: selectedDateRange.startDate,
                      endDate: selectedDateRange.endDate,
                      key: "selection",
                    },
                  ]}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={true}
                />
              </Grid>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button variant="text" onClick={handleDialogClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDialogConfirm}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>

      {/* Main Content Section */}
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        {/* Total delivered orders */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            color: "#1A73E8",
            fontSize: "15px",
          }}
        >
          Delivery Rate
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                Orders
              </Typography>
              {totalDataLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${totalData?.total ?? 0}`}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Grouped Cards */}
          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Delivered %
                </Typography>
                {totalDeliveredDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {totalDeliveredData?.totalDelivered?.percents?.find(
                      (item) => item.label === "Delivered",
                    )?.value ?? 0}
                    %{" "}
                  </Typography>
                )}
              </CardContent>
            </Card>

            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Undelivered %
                </Typography>
                {totalDeliveredDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {totalDeliveredData?.totalDelivered?.percents?.find(
                      (item) => item.label === "Undelivered",
                    )?.value ?? 0}
                    %{" "}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                ></Typography>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                ></Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Header */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            color: "#1A73E8",
            fontSize: "15px",
          }}
        >
          On Time Performance
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                On Time Performance %
              </Typography>
              {onTimePerformanceDataLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${onTimePerformanceData?.onTimePerformance ?? 0}%`}
                </Typography>
              )}
            </CardContent>
          </Card>
          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px", // Small gap
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            {/* Total On-Time */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total On-Time %
                </Typography>
                {deliveryPerformanceDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${deliveryPerformanceData?.totalOnTime ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/* Total Early */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Early %
                </Typography>
                {lateCulpabilitiesPerformanceDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${deliveryPerformanceData?.totalEarly ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/* */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                ></Typography>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                ></Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Late Culpabilities Performance Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                Total Late %
              </Typography>
              {deliveryPerformanceDataLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${deliveryPerformanceData?.totalLate ?? 0}%`}
                </Typography>
              )}
            </CardContent>
          </Card>

          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            {/* Late by Carrier */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Late by Carrier %
                </Typography>
                {lateCulpabilitiesPerformanceDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${lateCulpabilitiesPerformanceData?.lateByCarrier ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/* Late by Consignee */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Late by Consignee %
                </Typography>
                {lateCulpabilitiesPerformanceDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${lateCulpabilitiesPerformanceData?.lateByConsignee ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/* Late by Retailer */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Late by Retailer %
                </Typography>
                {lateCulpabilitiesPerformanceDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${lateCulpabilitiesPerformanceData?.lateByRetailer ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Header */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            color: "#1A73E8",
            fontSize: "15px",
          }}
        >
          Failed Deliveries
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                Total Failed %
              </Typography>
              {totalFailedDeliveriesDataLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${totalFailedDeliveriesData?.failed ?? 0}%`}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Grouped Cards */}
          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Manco %
                </Typography>
                {failedDeliveriesDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${failedDeliveriesData?.totalManco ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Returned %
                </Typography>
                {failedDeliveriesDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${failedDeliveriesData?.totalReturned ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Damaged/Lost %
                </Typography>
                {failedDeliveriesDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${failedDeliveriesData?.totalDamagedLost ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Header */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            color: "#1A73E8",
            fontSize: "15px",
          }}
        >
          Hit Rate
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                Total Attempted %
              </Typography>
              {hitRateDataLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {hitRateData?.hitRate?.percents?.find(
                    (item) => item.label === "Total Attempted",
                  )?.value ?? 0}
                  %{" "}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Grouped Cards */}
          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Successful 1st Attempt %
                </Typography>
                {hitRateDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {hitRateData?.hitRate?.percents?.find(
                      (item) => item.label === "Successful First Attempt",
                    )?.value ?? 0}
                    %{" "}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Not Delivered 1st Attempt %
                </Typography>
                {hitRateDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {hitRateData?.hitRate?.percents?.find(
                      (item) => item.label === "Not Delivered First Attempt",
                    )?.value ?? 0}
                    %{" "}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                ></Typography>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                ></Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RetailerBreakdown;
