import React, { useState, useEffect } from "react";
import { getRequestUI } from "common-utils/utils/api";
import { useQuery } from "react-query";
import { DateTime } from "luxon";
import { useOutletContext } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  LineChart,
  lineElementClasses,
  markElementClasses,
} from "@mui/x-charts/LineChart";
import { useTheme } from "@mui/material/styles";

const RetailerBenchmarks = () => {
  const [selectedOption, setSelectedOption] = useState("performance");
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const theme = useTheme();
  const { auth, userClaims } = useOutletContext() || {};
  const retailerId = userClaims?.retailerId;

  const colorPalette = [
    "#4caf50", // Registered: Green
    "#2196f3", // Confirmed: Blue
    "#ff9800", // On the way to pickup: Orange
    "#9c27b0", // Picked up: Purple
    "#673ab7", // Sorted: Deep Purple
    "#00bcd4", // Out for delivery: Cyan
    "#ffc107", // Order updated: Amber
    "#f44336", // Failed at carrier: Red
  ];

  const formatMonth = (dateString) =>
    DateTime.fromFormat(dateString, "yyyy-MM").toFormat("yyyy MMM");

  const { data: carrierList, isLoading: isCarriersLoading } = useQuery(
    ["carriers", retailerId],
    async () => {
      if (!retailerId) return [];
      return getRequestUI(`/retailer/carriers/${retailerId}`);
    },
    { enabled: !!retailerId, refetchOnWindowFocus: false },
  );

  const { data: countriesList, isLoading: isCountriesLoading } = useQuery(
    ["countries"],
    () => getRequestUI("/countries"),
    { refetchOnWindowFocus: false },
  );

  const fetchAnalyticsData = async (endpoint) => {
    const filters = {
      ...(selectedCarrier ? { carrierUUID: selectedCarrier } : {}),
      ...(selectedCountry ? { country: selectedCountry } : {}),
    };
    return getRequestUI(endpoint, filters);
  };

  const queryOptions = {
    refetchOnWindowFocus: false,
  };

  const benchmarksTotal = useQuery(
    ["benchmarks-total", selectedCarrier, selectedCountry],
    () => fetchAnalyticsData("/retailer/benchmarks/total"),
    queryOptions,
  );
  const benchmarksOpen = useQuery(
    ["benchmarks-open", selectedCarrier, selectedCountry],
    () => fetchAnalyticsData("/retailer/benchmarks/open"),
    queryOptions,
  );
  const benchmarksOnItsWay = useQuery(
    ["benchmarks-on-its-way", selectedCarrier, selectedCountry],
    () => fetchAnalyticsData("/retailer/benchmarks/onitsway"),
    queryOptions,
  );
  const benchmarksReturned = useQuery(
    ["benchmarks-returned", selectedCarrier, selectedCountry],
    () => fetchAnalyticsData("/retailer/benchmarks/returned"),
    queryOptions,
  );

  useEffect(() => {
    benchmarksTotal.refetch();
    benchmarksOpen.refetch();
    benchmarksOnItsWay.refetch();
    benchmarksReturned.refetch();
  }, []);

  const handleOptionChange = (_, newOption) => {
    if (newOption) setSelectedOption(newOption);
  };

  const openStatuses = [
    "Registered",
    "Confirmed",
    "On the way to pickup",
    "Picked up",
    "Sorted",
    "Out for delivery",
    "Order updated",
    "Failed at carrier",
  ];

  const dataset =
    selectedOption === "performance"
      ? benchmarksTotal.data?.percents || []
      : selectedOption === "open"
        ? benchmarksOpen.data?.percents || []
        : selectedOption === "on-its-way"
          ? benchmarksOnItsWay.data?.percents || []
          : benchmarksReturned.data?.percents || [];

  const keys =
    selectedOption === "performance"
      ? ["On Time"]
      : selectedOption === "open"
        ? openStatuses
        : selectedOption === "on-its-way"
          ? ["Out for Delivery"]
          : ["Returned to pickup"];

  const getPercentSeries = (data, keys) => {
    if (!data || data.length === 0) return [];
    return keys.map((key, index) => ({
      dataKey: key,
      label: key,
      color: colorPalette[index % colorPalette.length],
      stack: "total",
      labelProps: { dy: -30 },
      valueFormatter: (value) => `${value}%`,
    }));
  };

  const formattedDataset = dataset.map((item) => ({
    ...item,
    month: formatMonth(item.month),
    benchmark: 98,
  }));

  return (
    <Box sx={{ width: "100%", padding: 0, margin: 0 }}>
      {/* 
          Country & Carrier Filters 
      */}
      <Box
        sx={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ marginRight: "8px", marginTop: "10px" }}
        >
          Showing data for:
        </Typography>

        {/* Country Filter */}
        <TextField
          select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
          disabled={isCountriesLoading}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        >
          <option value="">All Countries</option>
          {(countriesList ?? []).map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </TextField>

        {/* Carrier Filter */}
        <TextField
          select
          value={selectedCarrier}
          onChange={(e) => setSelectedCarrier(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
          disabled={isCarriersLoading}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        >
          <option value="">All Carriers</option>
          {(carrierList ?? []).map((carrier) => (
            <option key={carrier.uuid} value={carrier.uuid}>
              {carrier.exportName || carrier.name}
            </option>
          ))}
        </TextField>
      </Box>

      {/* Comparison Card */}
      <Card variant="outlined" sx={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Comparison
          </Typography>

          {/* Toggle Buttons */}
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <ToggleButtonGroup
              value={selectedOption}
              exclusive
              onChange={handleOptionChange}
              aria-label="Benchmark Options"
              sx={{
                "& .MuiToggleButtonGroup-grouped": {
                  margin: 0.5,
                  borderRadius: "8px",
                  fontSize: "0.75rem",
                  padding: "4px 8px",
                  border: "1px solid",
                  borderColor: theme.palette.divider,
                },
              }}
            >
              <ToggleButton
                value="performance"
                sx={{
                  backgroundColor:
                    selectedOption === "performance"
                      ? theme.palette.primary.main
                      : "white",
                  color:
                    selectedOption === "performance"
                      ? "white"
                      : theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "white",
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  },
                }}
              >
                Performance
              </ToggleButton>
              <ToggleButton
                value="open"
                sx={{
                  backgroundColor:
                    selectedOption === "open"
                      ? theme.palette.primary.main
                      : "white",
                  color:
                    selectedOption === "open"
                      ? "white"
                      : theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "white",
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  },
                }}
              >
                Open
              </ToggleButton>
              <ToggleButton
                value="on-its-way"
                sx={{
                  backgroundColor:
                    selectedOption === "on-its-way"
                      ? theme.palette.primary.main
                      : "white",
                  color:
                    selectedOption === "on-its-way"
                      ? "white"
                      : theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "white",
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  },
                }}
              >
                On Its Way
              </ToggleButton>
              <ToggleButton
                value="returned"
                sx={{
                  backgroundColor:
                    selectedOption === "returned"
                      ? theme.palette.primary.main
                      : "white",
                  color:
                    selectedOption === "returned"
                      ? "white"
                      : theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "white",
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  },
                }}
              >
                Returned
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Render Chart or 'No data' */}
          {dataset.length > 0 ? (
            selectedOption === "performance" ? (
              <LineChart
                dataset={formattedDataset}
                series={[
                  {
                    dataKey: "On Time",
                    label: "On Time",
                    showMark: true,
                    labelProps: { position: "top", dy: -20 },
                    color: theme.palette.primary.main,
                    valueFormatter: (value) => `${value}%`,
                  },
                  {
                    id: "benchmarkId",
                    dataKey: "benchmark",
                    label: "Benchmark",
                    showMark: true,
                    color: "#f44336",
                    valueFormatter: (value) => `${value}%`,
                  },
                ]}
                xAxis={[{ scaleType: "band", dataKey: "month" }]}
                height={320}
                sx={{
                  [`& .${lineElementClasses.root}, & .${markElementClasses.root}`]:
                    {
                      strokeWidth: 1,
                    },
                  ".MuiLineElement-series-benchmarkId": {
                    strokeDasharray: "5 5",
                  },
                  [`& .${markElementClasses.root}:not(.${markElementClasses.highlighted})`]:
                    {
                      fill: "#fff",
                    },
                  [`& .${markElementClasses.highlighted}`]: {
                    stroke: "none",
                  },
                }}
              />
            ) : (
              <BarChart
                dataset={formattedDataset}
                series={getPercentSeries(dataset, keys)}
                xAxis={[
                  {
                    scaleType: "band",
                    dataKey: "month",
                    categoryGapRatio: 0.8,
                  },
                ]}
                height={320}
                grid={{ horizontal: true }}
                margin={{ left: 50, right: 20, top: 40, bottom: 20 }}
                borderRadius={8}
                slotProps={{ legend: { hidden: false } }}
              />
            )
          ) : (
            <Typography align="center" variant="subtitle1">
              No data available
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default RetailerBenchmarks;
