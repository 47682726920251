import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRangePicker } from "react-date-range";
import FormControlLabel from "@mui/material/FormControlLabel";

import { formatDate } from "common-utils/utils/formatter";
import {
  ButtonProgress,
  PageTitle,
  TableFilter,
  IconCalendar,
  MultiTextField,
  theme,
} from "@shared/components/lib/index";

const useStyles = (theme) => ({
  root: {
    padding: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  endAdornment: {
    top: 0,
    bottom: "calc(50% - 14px)",
  },
  warningBlock: {
    marginTop: 0,
  },
  buttonSubmit: {
    "&.MuiButton-root": {
      borderRadius: "4px",
      color: "#fff",
      backgroundColor: "#0635C9",
      width: "124px",
      fontFamily: "Nunito, sans-serif",
      textTransform: "none",
      height: "40px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  buttonOk: {
    "&.MuiButton-root": {
      backgroundColor: "#0635C9",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "12",
      padding: "4",
    },
  },
});

const Filters = ({
  setTitle,
  selectedStartPickUpDateFilter,
  setSelectedStartPickUpDateFilter,
  selectedEndPickUpDateFilter,
  setSelectedEndPickUpDateFilter,
  locations = [],
  carriers = [],
  retailers = [],
  statuses = [],
  reasons = [],
  selectedLocationsFilter,
  setSelectedLocationsFilter,
  selectedCarriersFilter,
  setSelectedCarriersFilter,
  selectedRetailersFilter,
  setSelectedRetailersFilter,
  selectedStatusFilter,
  setSelectedStatusFilter,
  selectedReasonFilter,
  setSelectedReasonFilter,
  onExportClick,
  hideExport,
  dateName = "Pickup date",
  exportLoading = false,
  selectedTrackingCodeFilter,
  setSelectedTrackingCodeFilter,
  getOrderByTrackingCodes,
  selectedZipCodeFilter,
  setSelectedZipCodeFilter,
  onApplyFilter,
  hideApplyFilter,
  statusLabel = "Delivery Status",
}) => {
  const styles = useStyles(theme);
  const [open, setOpen] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: selectedStartPickUpDateFilter,
      endDate: selectedEndPickUpDateFilter,
      key: "selection",
    },
  ]);

  const selectedDateRange = (range) => {
    setDateRange({
      startDate: range.selection.startDate,
      endDate: range.selection.endDate,
    });
  };
  const handleApply = () => {
    setSelectedStartPickUpDateFilter(dateRange[0].startDate);
    setSelectedEndPickUpDateFilter(dateRange[0].endDate);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetOrderByTrackingCode = () => {
    getOrderByTrackingCodes(selectedTrackingCodeFilter.join(","));
  };

  const trackingCodeFilterInputValid = (selectedTrackingCodeFilter) => {
    return selectedTrackingCodeFilter.length < 50; // warn before user reaches limit
  };

  return (
    <>
      <Grid
        container
        sx={styles.root}
        spacing={1}
        wrap="nowrap"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={10}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10}>
              <Grid container spacing={2} alignItems="end">
                {setSelectedLocationsFilter && (
                  <Grid item xs={12} sm={4}>
                    <TableFilter
                      data={locations}
                      label="Locations"
                      key="location"
                      selectedFilterData={selectedLocationsFilter}
                      setSelectedFilterData={setSelectedLocationsFilter}
                    />
                  </Grid>
                )}
                {setSelectedCarriersFilter && (
                  <Grid item xs={12} sm={4}>
                    <TableFilter
                      data={carriers}
                      label="Carriers"
                      key="carrier"
                      selectedFilterData={selectedCarriersFilter}
                      setSelectedFilterData={setSelectedCarriersFilter}
                    />
                  </Grid>
                )}
                {setSelectedStatusFilter && (
                  <Grid item xs={12} sm={4}>
                    <TableFilter
                      data={statuses}
                      label={statusLabel}
                      key="status"
                      selectedFilterData={selectedStatusFilter}
                      setSelectedFilterData={setSelectedStatusFilter}
                    />
                  </Grid>
                )}
                {setSelectedZipCodeFilter && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="standard"
                      label="Zip Code"
                      type="search"
                      fullWidth
                      value={selectedZipCodeFilter}
                      onChange={(e) => setSelectedZipCodeFilter(e.target.value)}
                    />
                  </Grid>
                )}
                {setSelectedRetailersFilter && (
                  <Grid item xs={12} sm={4}>
                    <TableFilter
                      data={retailers}
                      label="Retailers"
                      key="retailer"
                      selectedFilterData={selectedRetailersFilter}
                      setSelectedFilterData={setSelectedRetailersFilter}
                    />
                  </Grid>
                )}

                {setSelectedReasonFilter && (
                  <Grid item xs={12} sm={4}>
                    <TableFilter
                      data={reasons}
                      label="Reason"
                      key="reason"
                      selectedFilterData={selectedReasonFilter}
                      setSelectedFilterData={setSelectedReasonFilter}
                    />
                  </Grid>
                )}
                {selectedEndPickUpDateFilter && (
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>{`Select ${dateName}`}</InputLabel>
                      <Input
                        value={`${formatDate(
                          selectedStartPickUpDateFilter,
                        )} - ${formatDate(selectedEndPickUpDateFilter)}`}
                        fullWidth
                        onClick={() => setOpen(true)}
                        data-testid="scheduled_copy"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconCalendar />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Grid container justifyContent="end">
                {!hideApplyFilter &&
                  !(
                    selectedTrackingCodeFilter &&
                    selectedTrackingCodeFilter.length > 0
                  ) && (
                    <Button
                      onClick={onApplyFilter}
                      variant="contained"
                      color="primary"
                      data-testid="btn_apply_filters"
                      sx={styles.buttonSubmit}
                      margin="normal"
                    >
                      Apply filters
                    </Button>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems="end"
          >
            {selectedTrackingCodeFilter && (
              <Grid item xs={12} sm={10}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="end"
                >
                  <Grid item xs={12} sm={4}>
                    <MultiTextField
                      id="tracking_code_input"
                      label="Tracking code"
                      type="search"
                      fullWidth
                      values={selectedTrackingCodeFilter}
                      setValues={setSelectedTrackingCodeFilter}
                      error={
                        !trackingCodeFilterInputValid(
                          selectedTrackingCodeFilter,
                        )
                      }
                      helperText={
                        !trackingCodeFilterInputValid(
                          selectedTrackingCodeFilter,
                        )
                          ? "You can add up to 50 tracking codes only."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {selectedTrackingCodeFilter &&
                      trackingCodeFilterInputValid(
                        selectedTrackingCodeFilter,
                      ) &&
                      !!selectedTrackingCodeFilter.length && (
                        <Button
                          onClick={handleGetOrderByTrackingCode}
                          variant="contained"
                          color="primary"
                          sx={styles.buttonSubmit}
                          margin="normal"
                        >
                          Find
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!hideExport && (
              <Grid item xs={12} sm={2}>
                <Grid container justifyContent="end">
                  <Button
                    disabled={exportLoading}
                    onClick={onExportClick}
                    variant="contained"
                    color="primary"
                    sx={styles.buttonSubmit}
                    margin="normal"
                  >
                    Export
                    {exportLoading && <ButtonProgress size={24} />}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid container flexDirection="column" alignItems="end">
            <Grid item xs={12}>
              <DateRangePicker
                onChange={(ranges) => setDateRange([ranges.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={true}
                months={1}
                ranges={dateRange}
                direction="horizontal"
              />
            </Grid>
            <Grid item>
              <Grid container justifyContent="end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                  sx={styles.buttonOk}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Filters;
