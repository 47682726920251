import React, { useState } from "react";
import { getRequestUI } from "common-utils/utils/api";
import { DateTime } from "luxon";
import CustomizedPieChart from "../components/CustomPieChart";
import { useQuery } from "react-query";
import { DateRangePicker } from "react-date-range";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useOutletContext } from "react-router-dom";
import {
  LineChart,
  lineElementClasses,
  markElementClasses,
} from "@mui/x-charts/LineChart";
import {
  Box,
  CardContent,
  Card,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
const formatDate = (date) => DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

const formatDateDisplay = (date) =>
  DateTime.fromJSDate(date).toFormat("dd LLL yyyy");

const RetailerOverview = () => {
  const today = new Date();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 29,
    ),
    endDate: today,
  });
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [open, setOpen] = useState(false);
  const { auth, userClaims } = useOutletContext() || {};
  const retailerId = userClaims?.retailerId;

  const handleFieldClick = () => setOpen(true);

  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedDateRange({ startDate, endDate });
  };

  const handleDialogClose = () => setOpen(false);

  const handleDialogConfirm = () => {
    setDateRange(selectedDateRange);
    setOpen(false);
  };

  const { data: carrierList, isLoading: isCarriersLoading } = useQuery(
    ["carriers", retailerId],
    async () => {
      if (!retailerId) return [];
      return getRequestUI(`/retailer/carriers/${retailerId}`);
    },
    { enabled: !!retailerId, refetchOnWindowFocus: false },
  );

  const { data: countriesList, isLoading: isCountriesLoading } = useQuery(
    ["countries"],
    () => getRequestUI("/countries"),
    { refetchOnWindowFocus: false },
  );

  const fetchAnalyticsData = async (endpoint) => {
    const filters = {
      startDate: formatDate(dateRange.startDate),
      endDate: formatDate(dateRange.endDate),
      ...(selectedCarrier ? { carrierUUID: selectedCarrier } : {}),
      ...(selectedCountry ? { country: selectedCountry } : {}),
    };
    return getRequestUI(endpoint, filters);
  };

  const queryOptions = {
    enabled: !!dateRange.startDate && !!dateRange.endDate,
    refetchOnWindowFocus: false,
  };

  const { data: totalData, isLoading: totalDataLoading } = useQuery(
    [
      "total",
      dateRange.startDate,
      dateRange.endDate,
      selectedCountry,
      selectedCarrier,
    ],
    () => fetchAnalyticsData("/retailer/analytics/total"),
    queryOptions,
  );

  const { data: perCarrierData, isLoading: perCarrierDataLoading } = useQuery(
    ["per-carrier", dateRange.startDate, dateRange.endDate, selectedCountry],
    () => fetchAnalyticsData("/retailer/analytics/per-carrier"),
    queryOptions,
  );

  const { data: topCitiesData, isLoading: topCitiesDataLoading } = useQuery(
    [
      "top-cities",
      dateRange.startDate,
      dateRange.endDate,
      selectedCountry,
      selectedCarrier,
    ],
    () => fetchAnalyticsData("/retailer/analytics/top10-cities"),
    queryOptions,
  );

  const {
    data: firstAttemptResults,
    isLoading: firstAttemptResultsDataLoading,
  } = useQuery(
    [
      "first-attempt",
      dateRange.startDate,
      dateRange.endDate,
      selectedCountry,
      selectedCarrier,
    ],
    () => fetchAnalyticsData("/retailer/analytics/first-attempt-results"),
    queryOptions,
  );

  const { data: timeframeStatus, isLoading: timeframeStatusDataLoading } =
    useQuery(
      [
        "timeframe",
        dateRange.startDate,
        dateRange.endDate,
        selectedCountry,
        selectedCarrier,
      ],
      () => fetchAnalyticsData("/retailer/analytics/timeframe-status"),
      queryOptions,
    );

  const { data: timelineData, isLoading: timelineDataLoading } = useQuery(
    [
      "timeline",
      dateRange.startDate,
      dateRange.endDate,
      selectedCountry,
      selectedCarrier,
    ],
    () => fetchAnalyticsData("/retailer/analytics/timeline"),
    queryOptions,
  );
  const { data: volumeTrend, isLoading: volumeTrendLoading } = useQuery(
    [
      "volume-trend",
      dateRange.startDate,
      dateRange.endDate,
      selectedCountry,
      selectedCarrier,
    ],
    () => fetchAnalyticsData("/retailer/analytics/volume-trend"),
    queryOptions,
  );

  const lineChartData = volumeTrend?.trend || [];

  return (
    <Box sx={{ width: "100%", padding: "0px", margin: "0px" }}>
      <Box
        sx={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {" "}
        <Typography
          variant="body1"
          sx={{ marginRight: "8px", marginTop: "10px" }}
        >
          Showing data for:
        </Typography>
        {/* Country Filter Dropdown */}
        <TextField
          select
          value={selectedCountry || ""}
          onChange={(e) => setSelectedCountry(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
          disabled={isCountriesLoading}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        >
          <option value="">All Countries</option>
          {(countriesList ?? []).map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </TextField>
        <TextField
          select
          value={selectedCarrier || ""}
          onChange={(e) => setSelectedCarrier(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
          disabled={isCarriersLoading}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        >
          <option value="">All Carriers</option>
          {(carrierList ?? []).map((carrier) => (
            <option key={carrier.uuid} value={carrier.uuid}>
              {carrier.exportName || carrier.name}
            </option>
          ))}
        </TextField>
        {/* Date Range Input Field */}
        <TextField
          variant="outlined"
          size="small"
          value={`${formatDateDisplay(dateRange.startDate)} - ${formatDateDisplay(
            dateRange.endDate,
          )}`}
          onClick={handleFieldClick}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0", // Light gray border
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        />
        {/* Date Picker Dialog */}
        <Dialog open={open} onClose={handleDialogClose}>
          <DialogContent>
            <Grid
              container
              direction="column"
              sx={{
                padding: "20px 50px",
              }}
            >
              <Grid item>
                <DateRangePicker
                  ranges={[
                    {
                      startDate: selectedDateRange.startDate,
                      endDate: selectedDateRange.endDate,
                      key: "selection",
                    },
                  ]}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={true}
                />
              </Grid>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button variant="text" onClick={handleDialogClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDialogConfirm}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: 0,
          backgroundColor: "rgb(239, 243, 246)",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        {/* First Card */}
        <Box sx={{ flex: "1 1 calc(33.333% - 16px)", minWidth: "300px" }}>
          <Card
            sx={{ backgroundColor: "white", textAlign: "left", width: "100%" }}
          >
            <CardContent sx={{ minHeight: "260px" }}>
              <Typography variant="h6" sx={{ marginBottom: "8px" }}>
                Total
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "grey", marginBottom: "16px" }}
              >
                Shipping Summary
              </Typography>
              <Box
                sx={{
                  minHeight: "142px",
                  backgroundColor: "#E0E8FF",
                  borderRadius: "12px",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {totalDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        backgroundColor: "#4B7BFF",
                        borderRadius: "50%",
                        padding: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <img
                        src="https://img.icons8.com/ios-filled/50/ffffff/combo-chart.png"
                        alt="chart-icon"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </Box>

                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", color: "#000000" }}
                    >
                      {totalData?.total || "0"}
                    </Typography>

                    <Typography variant="body2" sx={{ color: "#5F6C7B" }}>
                      Shipments
                    </Typography>
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Second Card */}
        <Box sx={{ flex: "1 1 calc(33.333% - 16px)", minWidth: "300px" }}>
          <Card sx={{ width: "100%" }}>
            <CardContent sx={{ minHeight: "260px" }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Volume per carrier
              </Typography>
              {perCarrierDataLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 12 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ color: "text.secondary", mb: 1 }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Carrier
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Number
                    </Typography>
                  </Box>
                  {perCarrierData?.perCarrier.values
                    ?.slice(0, 5)
                    .map((carrier, index) => (
                      <React.Fragment key={index}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          py={0.5}
                        >
                          <Typography variant="body2">
                            {carrier.label}
                          </Typography>
                          <Typography variant="body2">
                            {carrier.value}
                          </Typography>
                        </Box>
                        {index < 4 && (
                          <Box sx={{ borderBottom: "1px solid #E0E0E0" }} />
                        )}
                      </React.Fragment>
                    ))}
                </>
              )}
            </CardContent>
          </Card>
        </Box>
        {/* Third Card */}
        <Box sx={{ flex: "1 1 calc(33.333% - 16px)", minWidth: "300px" }}>
          <Card sx={{ width: "100%" }}>
            <CardContent sx={{ minHeight: "260px" }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Volume Distribution
              </Typography>
              {topCitiesDataLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 12 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ color: "text.secondary", mb: 1 }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Location
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Number
                    </Typography>
                  </Box>
                  {topCitiesData?.top10Cities.values
                    ?.slice(0, 5)
                    .map((city, index) => (
                      <React.Fragment key={index}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          py={0.5}
                        >
                          <Typography variant="body2">{city.label}</Typography>
                          <Typography variant="body2">{city.value}</Typography>
                        </Box>
                        {index < 4 && (
                          <Box sx={{ borderBottom: "1px solid #E0E0E0" }} />
                        )}
                      </React.Fragment>
                    ))}
                </>
              )}
            </CardContent>
          </Card>
        </Box>
        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card sx={{ width: "100%" }}>
            <CardContent sx={{ minHeight: "260px" }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Volume Trend
              </Typography>
              {volumeTrendLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 12 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ width: "100%", overflowX: "auto" }}>
                  <LineChart
                    dataset={lineChartData}
                    series={[
                      {
                        dataKey: "count",
                        label: "Orders",
                        showMark: true,
                        color: "#1E88E5",
                        labelProps: { position: "top", dy: -10 },
                      },
                    ]}
                    xAxis={[
                      {
                        dataKey: "day",
                        scaleType: "band",
                      },
                    ]}
                    height={320}
                    sx={{
                      [`& .${lineElementClasses.root}, & .${markElementClasses.root}`]:
                        {
                          strokeWidth: 2,
                        },
                      [`& .${markElementClasses.root}:not(.${markElementClasses.highlighted})`]:
                        {
                          fill: "#fff",
                        },
                      [`& .${markElementClasses.highlighted}`]: {
                        stroke: "none",
                      },
                    }}
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default RetailerOverview;
