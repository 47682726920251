// Layout.js
import { Outlet, useOutletContext } from "react-router-dom";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { PageContainer } from "@toolpad/core/PageContainer";
import { Navigate } from "react-router-dom";

export default function Layout() {
  const { auth, userClaims } = useOutletContext() || {};

  return (
    <DashboardLayout>
      {" "}
      <PageContainer
        breadcrumbs=""
        sx={{
          maxWidth: "100%", // Allow full width
          margin: "0 auto",
          padding: "0 2vw", // Use dynamic padding
          [`@media (min-width: 1920px)`]: {
            maxWidth: "100%", // Slightly reduce width for better UX
          },
          [`@media (min-width: 2560px)`]: {
            maxWidth: "80%",
          },
          [`@media (min-width: 3200px)`]: {
            maxWidth: "75%",
          },
          [`@media (min-width: 5120px)`]: {
            maxWidth: "70%", // Ultra-wide monitors
          },
          [`@media (min-width: 7680px)`]: {
            maxWidth: "65%", // 8K screens
          },
          [`@media (min-width: 10240px)`]: {
            maxWidth: "60%", // Future ultra-wide screens
          },
        }}
      >
        {" "}
        <Outlet context={{ auth, userClaims }} />
      </PageContainer>
    </DashboardLayout>
  );
}
